.indructionHeader{
  position: relative;
}
.sticky {
  position: absolute;
  top: 0;
  width: 100%;
  font-size: 36px;
  height: 50px;
  background-color: white;
}
.sticky + .content {
  padding-top: 50px;
}
.Collapsible {
  font-size: 24px;
}
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;

}

  #tabRef1 > div > span{
    
    background-color: #DED5CE;
  }
  #tabRef1 > div > div{
    
    background-color: #D3CDE7;
  }
  #tabRef2 > div > span{
    
    background-color: #D0C6BC;
  }
  #tabRef2 > div > div{
    
    background-color: #E8D9C4;
  }
  #tabRef3 > div > span{
    
    background-color: #C5B9AD;
  }
  #tabRef3 > div > div{
    
    background-color: #B4D9B8;
  }
  body{
    background-color: #B4D9B8;
  }

@media screen and (max-width: 1023px) {
  .InstructionText{
    font-size: 6vw;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
  }
  .InstructionText2{
  
    font-size: 6vw;
  }
  .InstructionText3{
    font-size: 6vw;
    padding-left: 20px;
    text-align: left;
  }
  .imgIntructions1{
    width: 65vw;
  }
  .imgIntructions3{
    width: 35vw;
  }

  .CSOfficeHourDiv{
    font-size: 4vw;
  }
  .CollapsibleText{
    font-size: 6vw;
    padding-top: 3.5vw;
    padding-bottom: 3.5vw;
  }

    
  .imgInstructionsStep1{
    width: 20vw;
    position: absolute;
    left: 0px;
    top:-3px;
  }
  .imgInstructionsStep2{
    width: 16vw;
    position: absolute;
    left: 4.5vw;
    top:1px;
  }
  .imgInstructionsStep3{
    width: 14vw;
    position: absolute;
    left: 5vw;
    top:0px;
  }
  .Collapsible__trigger:after {
    content: "";
    background-image: url(./img/arrow_up.png);
    background-size: 100% 100%;
    height: 3.5vw;
    width: 6vw;
      position: absolute;
      right: 8vw;
      top: 6.5vw;
      display: block;
      transition: transform 300ms;
  }

    
  .imgInstructionsStep1Text{
    width: 40%;
    padding-top: 0px;
    font-size: 7vw;
  }
  .imgInstructionsStep2Text{
    width: 33%;
    padding-top: 5px;
    font-size: 7vw;
  }
  .imgInstructionsStep3Text{
    width: 46%;
    padding-top: 0px;
    font-size: 7vw;
  }
  .imgScanCode{
    width: 50%;
  }
  .imgBacktoHome{
    width: 50%;
  }
  
  .Instruction1TD1{
    font-size: 3.5vw;
  }

  .Instruction1TD2{
    font-size: 3.5vw;
    text-align: left;
  }
  .Instruction1Text{
    padding: 10px;
    background-color: yellow;
    color: red;
    font-size: 4.5vw;
  }
  .btncontainer{
    position: relative;
    text-align: center;
  }
  .btnText{
    position:absolute;
    top: 0px;
    width: 100%;
    font-weight: bold;
    font-size: 6.5vw;
    color: rgba(67, 42, 25, 1);
  }
}

@media screen and (min-width: 1024px) {
  .InstructionText{
    font-size: 36px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
  }
  .InstructionText2{
  
    font-size: 36px;
  }
  .InstructionText3{
    font-size: 36px;
    padding-left: 40px;
    text-align: left;
  }
  .imgIntructions1{
    width: 700px;
  }
  .imgIntructions3{
    width: 330px;
  }

  .CSOfficeHourDiv{
    font-size: 28px;
  }
  .CollapsibleText{
    font-size: 48px;
  }

   
  .imgInstructionsStep1Text{
    width: 50%;
    padding-top: 3px;
    font-size: 72px;
  }
  .imgInstructionsStep2Text{
    width: 50%;
    padding-top: 5px;
    font-size: 72px;
  }
  .imgInstructionsStep3Text{
    width: 50%;
    padding-top: 5px;
    font-size: 72px;
  }

  .imgInstructionsStep1{
    width: 190px;
    position: absolute;
    left: 0px;
    top:-3px;
  }
  .imgInstructionsStep2{
    width: 155px;
    position: absolute;
    left: 40px;
    top:0px;
  }
  .imgInstructionsStep3{
    width: 138px;
    position: absolute;
    left: 45px;
    top:0px;
  }
  .Collapsible__trigger:after {
    content: "";
    background-image: url(./img/arrow_up.png);
    background-size: 100% 100%;
    height: 40px;
    width: 70px;
      position: absolute;
      right: 60px;
      top: 65px;
      display: block;
      transition: transform 300ms;
    }
    
  .imgScanCode{
    width: 50%;
  }
  .imgBacktoHome{
    width: 50%;
  }
  
  .Instruction1TD1{
    font-size: 24px;
  }

  .Instruction1TD2{
    font-size: 24px;
    text-align: left;
  }

  .Instruction1Text{
    padding: 10px;
    background-color: yellow;
    color: red;
    font-size: 26px;
  }

  .CollapsibleText{
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .btncontainer{
    position: relative;
    text-align: center;
  }
  .btnText{
    position:absolute;
    top: 0px;
    width: 100%;
    font-weight: bold;
    font-size: 68px;
    color: rgba(67, 42, 25, 1);
  }
}
.Collapsible{
  outline: none;
}
.Collapsible:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}
.Collapsible__trigger{
  outline: none;
}
.Collapsible__trigger:focus{
  outline: none;
}
.CollapsibleText{
  outline: none;
}
.CollapsibleText:focus{
  outline: none;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}


.InstructionButton{
font-size: 36px;
display: none;
}

.indructionPageHeaderBorder{
position: relative;
}

.indructionMain {
display: table;
margin: auto;
}
.indructionSubMain {
vertical-align: middle;
max-width: 1024px;
}


.Step3Table{
width: 100vw;
max-width: 900px;
padding-right: 20px;
}

*:focus {
  outline: none;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}
&:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}




.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.WeChatQRCode{
 width: 70vw;
 max-width: 500px;
}

.WhatsAppText{
  color: black;
  text-decoration:none;
}
