.App {
  text-align: center;
  font-family: '微軟正黑體','Microsoft JhengHei', '微软雅黑', 'Microsoft YaHei', 'sans-serif';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.DesktopDiv{
  font-size: 36px;
}
.ScanPageHeader{
  font-size: 36px;
  padding-top: 50px;
  padding-bottom: 50px;
}
/*
.WelcomePageHeader{
  font-size: 36px;
  padding-top: 70px;
  padding-bottom: 10px;
}*/
.WelcomePageButton{
  font-size: 36px;
}
.ResultPageHeader{
  font-size: 36px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ResultPageResultText1{
  font-size: 36px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}
.ResultPageResultText2{
  font-size: 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-top: 50px;
}

.Language{
  font-size: 20px;
  text-align: center;
  padding:20px;
}


.btninstructions{
  visibility: hidden;
  display: none;
}

body {
  vertical-align: middle;
  background-color: #E8D9C4;
}
/**********Welcome page start**********/
.WelcomePageMain {
  display: table;
  margin: auto;
  height: calc(100vh - 48px);
  background-color: rgba(178, 213, 183, 1);
  max-width: 1024px;
  width: 100vw;
}
.WelcomePageSubMain {
  vertical-align: middle;
}
.WelcomePageHeader{
  position: relative;
}
.WelcomePageHeaderBorder{
  position: relative;
}

.imgWelcomePageHeader{
  width: 100%;
  max-width: 1024px;
  position: relative;
  vertical-align:middle;
}
.imgWelcomePageBG{
  position: absolute;
  width: 100%;
  max-width: 1024px;
  left: 0px;
  top: -10px;
}
.imglangTW{
  
  position: absolute;
  width:7%;
  max-width: 56px;
  top: 19%;
  left: 1.5%;
}
.imglangCN{
  
  position: absolute;
  width:7%;
  max-width: 56px;
  top: 33%;
  left: 1.5%;
}
.ScanPageimglangTW{
  
  position: absolute;
  width:7%;
  max-width: 56px;
  top: 19%;
  left: 1.5%;
}
.ScanPageimglangCN{
  
  position: absolute;
  width:7%;
  max-width: 56px;
  top: 33%;
  left: 1.5%;
}
.ResultPageimglangTW{
  
  position: absolute;
  width:7%;
  max-width: 56px;
  top: 14%;
  left: 1.5%;
}
.ResultPageimglangCN{
  
  position: absolute;
  width:7%;
  max-width: 56px;
  top: 23%;
  left: 1.5%;
}
.WelcomePageStep1Border1{
  position: relative;
  max-width: 1024px;
}
.QrCodeVideoIpghone{
  visibility: hidden;
}
/*mobile*/
@media screen and (max-width: 1023px) {
  .WelcomePageStep1Border2{
    position: absolute;
    text-align: left;
    width: 100%;
    margin-top: -10%;
  }


  .imgWelcomePageStep1{
    width: 61vw;
    padding-left: 4vw;
  }
  .WelcomePageStep1Text{
    position: absolute;
    z-index: 2;
    font-size: 5vw;
    left: 12vw;
    top: 42vw;
    font-weight: bold;
    color: rgba(67, 42, 25, 1);
  }
  .WelcomePageStep2Text{
    position: absolute;
    z-index: 2;
    font-size: 5vw;
    left: 20vw;
    top: 75vw;
    font-weight: bold;
    color: rgba(67, 42, 25, 1);
  }
  .imgWelcomePageText1{
    position: absolute;
    width: 9vw;
    left: 6vw;
  }
  .imgWelcomePageText2{
    position: absolute;
    width: 9vw;
    left: 15vw;
    top: 38vw;
  }

  .WelcomePageStep2Border2{
    position: absolute;
    text-align: left;
    top: 31vw;
    left: 26vw;
  }

  .imgWelcomePageStep2{
    width: 53vw;
  }


  .WelcomePageStep3Border2{
    position: absolute;
    text-align: left;
    top: 63vw;
    left: 36vw;
  }
  .imgWelcomePageText3{
    position: absolute;
    width: 9vw;
    left: 25vw;
    top: 70vw;
  }

  .imgWelcomePageStep3{
    width: 50vw;
  }

  .ScanPagebtncontainer{
    padding-top: 5px;
    position: relative;
    text-align: center;
  }
  .ScanPagebtnText{
    position:absolute;
    top: 4px;
    width: 100%;
    font-weight: bold;
    font-size: 22px;
    color: rgba(67, 42, 25, 1);
  }
  .imgNeedHelp{
    width: 140px;
    padding: 2px;
  }
  .imgHome{
    width: 140px;
    padding-top: 2px;
  }
  .imgwhatsapp{
    width: 80vw;
  }
  .imgwechat{
    width: 80vw;
  }
  .CSOfficeHourDiv{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .hrLine{
    width: 80vw;
  }
  .ResultPageTD{
    padding-top: 10px;
  }
  .ResultPageTD2{
    padding-left: 10px;
    font-size: 6vw;
  }
  
  .MobileView{
    display: block;
  }
  .DestopView{
    display: none;
  }
  .imgscanBorder{
    position: absolute;
    width: 50vw;
    left: 25vw;
    top: calc(50vh - 200px);
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    .imgscanBorder{
      position: absolute;
      width: 80vw;
      left: 10vw;
      top: calc(50vh - 600px);
    }
  }
  .ResultPageHeaderText{
    bottom: 0px;
    font-size: 5vw;
    font-weight: bold;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6vw;
    padding-right: 6vw;
    text-align: left;
  }
}
/*Desktop start */

@media screen and (min-width: 1024px) {
  .WelcomePageStep1Border2{
    position: absolute;
    text-align: left;
    width: 100%;
    margin-top: -10%;
  }
  
  .WelcomePageStep1Text{
    position: absolute;
    z-index: 2;
    font-size: 52px;
    left: 130px;
    top: 435px;
    font-weight: bold;
    color: rgba(67, 42, 25, 1);
  }
  .WelcomePageStep2Text{
    position: absolute;
    z-index: 2;
    font-size: 52px;
    left: 210px;
    top: 780px;
    font-weight: bold;
    color: rgba(67, 42, 25, 1);
  }
  
  .imgWelcomePageStep1{
    width: 640px;
    padding-left: 40px;
  }
  .imgWelcomePageText1{
    position: absolute;
    width: 95px;
    left: 65px;
  }
  .imgWelcomePageText2{
    position: absolute;
    width: 95px;
    left: 150px;
    top: 400px;
  }
  
  .WelcomePageStep2Border2{
    position: absolute;
    text-align: left;
    top: 320px;
    left: 270px;
  }

  .imgWelcomePageStep2{
    width: 560px;
  }

  
  
  .WelcomePageStep3Border2{
    position: absolute;
    text-align: left;
    top: 660px;
    left: 370px;
  }
  .imgWelcomePageText3{
    position: absolute;
    width: 95px;
    left: 260px;
    top: 750px;
  }

  .imgWelcomePageStep3{
    width: 530px;
  }

  
  .imgNeedHelp{
    width: 140px;
    padding-top: 2px;
  }
  .imgHome{
    width: 140px;
    padding-top: 2px;
  }
  .imgwhatsapp{
    width: 700px;
  }
  .imgwechat{
    width: 700px;
  }
  .CSOfficeHourDiv{
    padding-bottom: 10px;
  }
  .ResultPageTD{
    padding-top: 10px;
    font-size: 30px;
  }
  .ResultPageTD2{
    padding-left: 10px;
    font-size: 30px;
  }
  .CSOfficeHourDiv{
    font-size: 30px;
  }
  .ResultPagecontactText1{
    font-size: 30px !important;
  }
  .ResultPageAlertText{
    font-size: 30px !important;
    color: red;
  }
  .ResultPageText1{
    font-size: 30px !important;
  }
  .MobileView{
    display: none;
  }
  .DestopView{
    display: block;
  }
  
  .imgscanBorder{
    position: absolute;
    width: 50%;
    left: 25%;
    top: calc(50vh - 200px);
  }
  
  .ScanPagebtncontainer{
    padding-top: 5px;
    position: relative;
    text-align: center;
  }
  .ScanPagebtnText{
    position:absolute;
    top: 4px;
    width: 100%;
    font-weight: bold;
    font-size: 22px;
    color: rgba(67, 42, 25, 1);
  }
  
  .ResultPageHeaderText{
    bottom: 0px;
    font-size: 50px;
    font-weight: bold;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 80px;
    padding-right: 80px;
    text-align: left;
  }
}
/*Desktop end*/

/**********Welcome page end**********/

/**********Scan page start**********/

.ScanPageMain {
  display: table;
  margin: auto;
}
.ResultPageMain {
  display: table;
  margin: auto;
}
.ScanPageSubMain {
  vertical-align: middle;
  max-width: 1024px;
}
.ScanPageHeader2{
  position: relative;
}
.ScanPageBG{
  height: 100px;
}

.ScanPageCameraDiv{
  position: absolute;
  width: 100%;
  background-color: black;
  z-index: -1;
}

.ScanPageBlackDiv{
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0px;
  background-color: black;
  z-index: -2;
}
.DivScanPageBottomButton{
  
  position:fixed;
  bottom: 0px;
  max-width: 1024px;
  width: 100vw;
}
.BtnScanPageNeedHelp{
  position: absolute;
  width: 50%;
  left: 0px;
  bottom: 0px;
  height: 40px;
  font-size: 24px;
  background-color: #E8D9C4;
}

.BtnScanPageReturnMainpage{
  position: absolute;
  width: 50%;
  bottom: 0px;
  right: 0px;
  height: 40px;
  font-size: 24px;
  background-color: #E8D9C4;
  border-left: 1px solid black;
}

.BottomText{
  position: fixed;
  max-width: 1024px;
  width: 100vw;
  bottom: 40px;
  background-color: #D22626;
  color: white;
  font-size: 24px;
  height: 80px;
  vertical-align: middle;
  line-height: 78px;

}
.BottomText2{
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.DivChangeCamera{
  position:fixed;
  bottom: 44px;
  max-width: 1024px;
  width: 100vw;
}
.BtnChangeCamera{
  position:absolute;
  bottom: 20px;
  left:10px;
}
.DivCameraInfo{
  position:absolute;
  bottom: 0px;
  left: 23px;
  color: white;
}
.ScanPageCameraContent{
  position: relative;
  width: 100vw;
  max-width: 1024px;
}
.ScanPageLoading{
  position: absolute;
  top: 0px;
  z-index: -1;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.ScanPageHeaderTextDiv{
  height: 50px;
  font-size: 26px;
  background-color: rgba(220, 213, 205, 1);
  vertical-align: middle;
  line-height: 43px;
}
.ScanPageHeaderIcon{
  position: absolute;
  width: 60px;
  left: 20px;
}
/**********Scan page end**********/
/**********Result page start**********/
.ResultPageBG{
  background-color: #DDD4CF;
}

.ResultPageHeaderBorder{
  position: relative;
  background-color: #D22626;
}

.ResultPageText1{
  background-color: #D22626;
  color: white;
  font-size: 6vw;
  padding-top: 5px;
  padding-bottom: 10px;
}
.ResultPageButton{
  position: fixed;
  width: 100%;
  max-width: 1024px;
  height: 40px;
  font-size: 24px;
  background-color: #E8D9C4;
  bottom: 0px;
}

.ResultPagecontact{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.ResultPagecontactText1{
  font-size: 6vw;
  text-align: left;
  color: black;
}
.ResultPageAlertBorder{
  position: relative;
  background-color: yellow;
}
.ResultPageAlertText{
  font-size: 6vw;
  text-align: left;
  color: red;
  padding-left:20px;
  padding-right:20px;
}

.ValidResultPageResultText1{
  font-size: 36px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}
.NotValidResultPageResultText1{
  font-size: 36px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  color : red;
}
/**********Result page End**********/

@media screen and (orientation: landscape)
{
  .ScanPageHeaderDisplay{
    display: none;
  }
}
@media screen and (orientation: landscape)
{
  .ScanPageCameraDiv{
    top:0px !important;
  }
}



.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.WeChatQRCode{
 width: 70vw;
 max-width: 500px;
}
.ResultPageHeaderText{
  bottom: 0px;
  font-weight: bold;
  color: white;
  text-align: left;
}
.ResultPageResultText{
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  color: white;
}

/****Top Menu Bar Start ****/
.TopMenuBarMainborder{
  z-index: 999;
}
.TopMenuBarMain{
  display: table;
  margin: auto;
  width: 100vw;
  max-width: 1024px;
}
.TopMenuBar{
  position: fixed;
  height: 48px;
  background-color: rgba(242, 238, 235, 1);
  text-align: right;
  max-width: 1024px;
  width: 100vw;
  z-index: 999;
}
.TopMenuBarHome{
  padding-left: 15px;
  padding-top: 10px;
  height: 22px;
}
.TopMenuBarChangeLang{
  padding-left: 10px;
  padding-top: 10px;
  height: 22px;
}
.TopMenuBarHelp{
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 10px;
  height: 22px;
}
.TopMenuBarScan{
  padding-left: 10px;
  padding-top: 10px;
  height: 22px;
}
.TopMenuBarTDRightDiv{
  padding-right: 15px;
}
.MainContent{
  padding-top: 48px;
}
.MenuEnvironment{
  padding-left: 20px;
  padding-top: 3px;
  font-size: 24px;
}
.TopMenuBarChangeLangDiv{
  width: 50px;
  display: inline-block;
}
/****Top Menu Bar End ****/
.ResultQRCode{
  white-space: initial;
  width: 80vw;
  word-wrap: break-word;
  text-align: left;
  font-size: 4vw;
}